@import 'styles/theme';

.Passwords {
  position: relative;

  .label {
    display: inline-block;
    margin-top: 10px;
    line-height: 1.1;
  }

  .error {
    color: theme($colors, 'red');
    display: none;

    &.show {
      display: block;
    }
  }
}
