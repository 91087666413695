@import 'styles/theme';

$large_width: 16px;
$large_height: 16px;
$small_width: 10px;
$small_height: 10px;

.Checkbox {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.small {
    .label {
      margin-top: -0.1em;
    }

    .checkboxContainer {
      width: $small_width;
      height: $small_height;
    }

    .checkmark {
      width: $small_width;
      height: $small_height;
    }
  }

  &.withBackground {
    padding-top: theme($spacings, 15);
    padding-bottom: theme($spacings, 15);
    padding-left: theme($spacings, 20);
    padding-right: theme($spacings, 20);
    background: theme($colors, 'gray-96');
    flex-direction: row-reverse;
    justify-content: space-between;

    .label {
      margin-left: 0;
      margin-right: theme($spacings, 10);
    }

    .checkmark {
      background: theme($colors, 'white');
    }
  }

  .checkboxContainer {
    width: $large_width;
    height: $large_height;
    display: inline-block;
  }

  .checkmark {
    display: block;
    position: relative;
    box-sizing: border-box;
    background-color: theme($colors, 'gray-93');
    top: 0;
    left: 0;
    width: $large_width;
    height: $large_height;
    overflow: hidden;

    &.withError {
      border-color: 1px solid theme($colors, 'red');
    }

    &.disabled {
      cursor: not-allowed;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 200%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: theme($colors, 'gray-84');
      }
    }

    &:after {
      visibility: hidden;
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: theme($colors, 'gray-31');
    }
  }

  .label {
    line-height: 1.3;
    margin-left: theme($spacings, 10);
  }

  .input {
    position: relative;
    margin-top: 0;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 0;
      appearance: none;
      padding: 0;
      outline: none;
    }

    &:not(.withCustomBackground) {
      input:checked ~ .checkmark {
        &:after {
          visibility: visible;
        }
      }
    }
  }
}
