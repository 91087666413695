@import 'styles/theme';

.LayoutUnLogged {
  position: relative;
  width: 100%;
  padding-top: theme($navigationHeight, 'mobile');

  @include from('lg') {
    padding-top: theme($navigationHeight, 'desktop');
  }

  .grid {
    padding-top: theme($spacings, 50);

    @include from('md') {
      padding-top: 10.42vw;
    }

    @include from('xxl') {
      padding-top: theme($spacings, 150);
    }
  }

  .imageContainer {
    display: none;

    @include from('md') {
      display: block;
      width: 100%;
      position: absolute;
    }
  }

  .ratio {
    position: relative;
  }

  .background,
  .component {
    grid-row: 1;
    z-index: 0;
  }

  .background {
    display: none;
    height: 100%;
    background-color: theme($colors, 'white');

    @include from('md') {
      display: block;
      border: solid 1px theme($colors, 'gray-93');
      grid-column: 4 / span 9;
    }

    @include from('lg') {
      grid-column: 5 / span 7;
    }
  }

  .component {
    position: relative;
    grid-column: 1 / -1;
    box-sizing: border-box;
    z-index: 1;
    border-top: solid 1px theme($colors, 'gray-68');
    padding-top: theme($spacings, 20);

    @include from('md') {
      margin: 6.94vw 0;
      grid-column: 5 / span 7;
    }

    @include from('lg') {
      grid-column: 6 / span 5;
    }

    @include from('xxl') {
      margin: theme($spacings, 100) 0;
    }
  }
}
