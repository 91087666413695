@import 'styles/theme';

.Civility {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .civilityInputsTitle {
    margin-top: theme($spacings, 20);
    margin-right: theme($spacings, 10);
  }
}
