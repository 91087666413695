@import 'styles/theme';

.LoadingPage {
  min-height: 100vh;
  background-color: theme($colors, 'white');
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;

  .loader {
    width: 40px;
    position: relative;
    text-align: center;
    margin: auto;
  }
}
