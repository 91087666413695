@import 'styles/theme';

.RadioGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.withBackground {
    position: relative;

    .errors {
      top: 0;
      transform: translate3d(0, calc(-100% - #{theme($spacings, 10)}), 0);
      margin-top: 0;
    }
  }

  .radios {
    display: flex;
    flex-direction: row;
  }

  .errors {
    color: theme($colors, 'red');
    margin-top: theme($spacings, 10);
    text-align: right;
    position: absolute;
    right: 0;

    * {
      letter-spacing: normal;
      line-height: 1;
    }
  }
}
