@import 'styles/theme';

.Password {
  position: relative;

  .seePassword {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    fill: theme($colors, 'gray-74');

    &.seePasswordActive {
      fill: theme($colors, 'black');
    }
  }
}
