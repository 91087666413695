@import 'styles/theme';

.ResetPassword {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .text {
    margin-top: theme($spacings, 15);
    margin-bottom: theme($spacings, 10);
  }

  .subtitle {
    margin: theme($spacings, 30) 0 theme($spacings, 50);
  }

  .form {
    display: flex;
    flex-direction: column;

    .submit {
      margin-top: theme($spacings, 50);
    }
  }
}
