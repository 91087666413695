@import 'styles/theme';

.BirthDate {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .label {
    max-width: 30%;
  }

  .inputs {
    display: flex;
    flex: 1;
  }

  .dateBirthInput {
    flex: 1;

    input {
      appearance: textfield;
      padding-bottom: theme($spacings, 10);
      padding-right: 0;
      text-align: left;
      background-color: theme($colors, 'white');
      color: theme($colors, 'gray-47');

      &:focus {
        color: theme($colors, 'gray-20');
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &:last-child {
      flex: 1.5;
    }

    @include touchDevicesFrom('xxxs') {
      &.filled {
        input[type='date']:after {
          content: none;
        }
      }

      input[type='date']:after {
        color: theme($colors, 'gray-47');
        content: attr(placeholder);
        width: 100%;
        position: absolute;
        left: 0;
        bottom: theme($spacings, 10);
      }
    }
  }

  span {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: theme($spacings, 10);
  }
}
