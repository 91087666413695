@import 'styles/theme';

.ForgotPassword {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    padding-top: theme($spacings, 20);
  }

  .subtitle {
    margin: theme($spacings, 30) 0 theme($spacings, 50);
  }

  .form {
    display: flex;
    flex-direction: column;

    .submit {
      margin-top: theme($spacings, 50);
    }
  }
}
