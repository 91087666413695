@import 'styles/theme';

.Login {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;

  .form {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .ctaWrapper {
      margin-top: theme($spacings, 50);
    }

    .forgotPassword {
      text-align: left;
      margin-top: theme($spacings, 30);
    }

    &.panel {
      height: 100%;
      box-sizing: border-box;

      .center {
        padding-top: theme($spacings, 50);
        margin-bottom: theme($spacings, 50);

        &::-webkit-scrollbar {
          display: none;
        }

        .firstInput {
          margin-top: 0;
        }
      }

      .title {
        padding-top: theme($spacings, 20);
      }

      .ctaWrapper {
        width: 100%;
        padding-top: theme($spacings, 30);
        position: relative;
        justify-self: flex-end;
        margin-top: auto;

        &:before {
          content: '';
          position: absolute;
          left: calc(#{theme($mobile, 'gutter')} * -1);
          right: calc(#{theme($mobile, 'gutter')} * -1);
          background-color: theme($colors, 'gray-93');
          height: 1px;
          top: 0;

          @include from('md') {
            left: calc(#{theme($spacings, 60)} * -1);
            right: calc(#{theme($spacings, 60)} * -1);
          }
        }
      }
    }
  }

  .register {
    text-align: center;
    margin-top: theme($spacings, 10);

    @include from('md') {
      margin-top: theme($spacings, 20);
    }

    .link {
      display: inline;
      margin-left: theme($spacings, 5);
      cursor: pointer;
    }
  }
}
