@import 'styles/theme';

.Radio {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: theme($spacings, 10);

  &:last-of-type {
    margin-right: 0;
  }

  &.withBackground {
    width: 100%;
    padding-top: theme($spacings, 15);
    padding-bottom: theme($spacings, 15);
    padding-left: theme($spacings, 20);
    padding-right: theme($spacings, 20);
    background: theme($colors, 'gray-96');
    box-sizing: border-box;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0;

    .label {
      margin: 0;
      line-height: 1.79;
    }

    .container,
    .checkmark {
      background: theme($colors, 'white');
      width: 16px;
      height: 16px;
    }
  }

  .container {
    width: 10px;
    height: 10px;
    display: inline-block;
    line-height: 0;
  }

  .label {
    line-height: 1;
    margin-left: theme($spacings, 10);
  }

  .inputComponent {
    position: relative;
    margin-top: 0;
    border: 0;

    .input {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      appearance: none;

      &.withError {
        opacity: 0;
      }

      &:focus {
        border: 0;
      }
    }

    input:checked ~ .checkmark:after {
      visibility: visible;
    }

    input ~ .checkmark.withError {
      color: red;
    }
  }

  .checkmark {
    display: block;
    position: relative;
    box-sizing: border-box;
    background-color: theme($colors, 'gray-93');
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    line-height: 0;

    &.withError {
      border: 1px solid theme($colors, 'red');
    }

    &:after {
      visibility: hidden;
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: theme($colors, 'black');
    }
  }
}
